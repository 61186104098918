
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};
const Divider = makeShortcode("Divider");
const Button = makeShortcode("Button");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`404: Resource Not Found`}</h1>
    <Divider mdxType="Divider" />
    <p>{`If you believe you have received this message in error, please contact
`}<a parentName="p" {...{
        "href": "mailto:help@openaba.com"
      }}>{`help@openaba.com`}</a>{`.`}</p>
    <Button size="large" type="primary" href='/manage' mdxType="Button">
  Return to Console
    </Button>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;